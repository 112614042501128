import React, { useState, useEffect } from 'react';
import Modal from "../Etalonnage/modal";
import useModal from "../Etalonnage/useModal";
import axios from "axios";
import { API_URL } from "../constants";

const SetLimitCourantMoteur = ({ motor, token, setMotor }) => {
  const { isShowing, toggle } = useModal();
  const [calibrationData, setCalibrationData] = useState({
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

    let courantNom = motor.courantNom;
    courantNom = courantNom * 1.1
    courantNom = courantNom.toFixed(2);
    let string = "Si aucune valeur n'est entrée, la limite de courant par défaut sera 10% superieure au courant nominal.";

  if (!motor.courantNom || 
    motor.courantNom === null ||
    motor.courantNom === undefined){
    courantNom = motor.courantRef
    if (motor.etalonnageCourant){
      courantNom = motor.courantRef + motor.etalonnageCourant
    }
    courantNom = courantNom * 1.1
    courantNom = courantNom.toFixed(2);
    string = "Si aucune valeur n'est entrée, la limite de courant par défaut sera 10% superieure premier courant meusurée par DiagMotor.";
  }

  const [currentValue, setCurrentValue] = useState(motor.limitCourant || '');

  useEffect(() => {
    setCurrentValue(motor.limitCourant);
  }, [motor.limitCourant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [type, field] = name.split("-");
    const parsedValue = parseFloat(value);
    setCurrentValue(e.target.value);
    setCalibrationData({
      ...calibrationData,
      [type]: {
        ...calibrationData[type],
        [field]: isNaN(parsedValue) ? "" : parsedValue
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const adjustedData = { id_moteur: motor._id, limitCourant: currentValue, };
      
     
    try {
      const response = await axios.post(`${API_URL}/moteur/setlimitcourant`, adjustedData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const updatedMotor = response.data;
        setMotor(updatedMotor);
        setSuccessMessage("Les données de limite de courant ont été envoyées avec succès.");
        setErrorMessage("");
        console.log("Calibration data sent successfully:", updatedMotor);
        // Auto-hide the success message after 3 seconds and close the modal
        setTimeout(() => {
          setSuccessMessage("");
          toggle();
        }, 3000);
      } else {
        setSuccessMessage("");
        setErrorMessage("Échec de l'envoi des données de limit de courant.");
        console.error("Failed to send calibration data:", response.status);
        // Auto-hide the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage("Erreur lors de l'envoi des données de limit de courant.");
      console.error("Error sending calibration data:", error);
      // Auto-hide the error message after 5 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <div>
      <h4 className="border-solid border-4 border-b-0 border-r-0 border-l-0 border-bleuSTB pt-4 pb-4 text-xl 3xl:text-3xl underline">
        <div className="inline-flex font-bold underline mb-4">
        Seuil d'alerte de courant du Moteur
        </div>
      </h4>
      <button onClick={toggle} className="bg-bleuSTB hover:bg-orange-500 text-white text-base border-none rounded-xl p-2 w-40 sm:w-60 cursor-pointer 3xl:text-2xl">
        Changer le seuil d'alerte de courant
      </button>
      <Modal isShowing={isShowing} hide={toggle} title="Limite de courant">
      <p className="text-red-500 mb-4 text-xs">{string}</p> 
      <p className="text-red-500 mb-4 text-xs">Limite de courant par default : {courantNom} A</p>
      <p className="text-red-500 mb-4 text-xs">Sinon, saisissez la valeur à ne pas dépasser.</p>
        <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700 font-bold mb-2">Seuil d'alerte de courant (à ne pas dépasser):</label>
              <input 
                type="text" 
                name="current" 
                onChange={handleChange} 
                value={currentValue}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                pattern="\d*\.?\d+"
                title="Veuillez entrer un nombre valide"
              />
            </div>
          <button 
            type="submit" 
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Valider
          </button>
        </form>
        {successMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 border border-green-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Succès!</strong>
            <span className="block sm:inline"> {successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 border border-red-700 text-white px-4 py-3 rounded transition-opacity duration-500 ease-in-out opacity-100">
            <strong className="font-bold">Erreur!</strong>
            <span className="block sm:inline"> {errorMessage}</span>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SetLimitCourantMoteur;
